<template>
  <ErrorBoundary
    module-name="WidgetsRenderer"
    :is-production-stand="isProductionStand"
  >
    <template #error>
      <p>Неизвестная ошибка</p>
    </template>
    <div>
      <CommentsWidgetContainer v-if="widget.type === 'comments'" />

      <BlockPromise v-else-if="widget.type === 'health-promise'" />

      <TilesRubrics
        v-else-if="widget.type === 'health-cats-collection'"
        :items="widget.items"
      />

      <HealthButtonCollection
        v-else-if="widget.type === 'health-button-collection'"
        :id="widget.id"
        :field-type="widget.fieldType"
        :title="widget.title"
        :category="widget.category"
        :name-search="widget.nameSearch"
        :tag="widget.tag"
        :link="widget.link"
      />

      <TaxonomyPostsContainer
        v-else-if="widget.type === 'taxonomy-posts' && widget.items.length"
        :articles="widget.items"
        :title="widget.title"
        :in-sidebar="inSidebar"
      />

      <NewsWidget
        v-else-if="widget.type === 'news'"
        :widget="widget"
      />

      <PostCollectionContainer
        v-else-if="widget.type === 'posts-collection' && widget.items && widget.items.length"
        :articles="widget.items"
        :in-sidebar="inSidebar"
        :title="widget.title"
      />

      <RecommendationBlock
        v-else-if="widget.type === 'recommendation-block'"
        :id="widget.id"
        :title="widget.title"
        :items="getFormattingArticles(widget.items || [])"
      />

      <RecipesWidgetContainer
        v-else-if="widget.type === 'recipes-list'"
        :title="widget.title"
        :items="widget.recipes"
        :more-link="widget.moreLink"
        :display-type="recipesWidgetDisplayType"
        :area="recipesWidgetDisplayType === 'big-tiles' ? 'в рецепте' : 'сайдбар'"
        :recipes-widget-event-category="recipesWidgetEventCategory"
        :list="widget.list"
        @show-more="onRecipesWidgetShowMore"
        @click-recipe="onRecipesWidgetClickRecipe"
      />

      <ClientOnly v-else>
        <HtmlWidgetContainer
          v-if="widget.type === 'parsed-html' && isHeadScriptsLoaded"
          :widgets-array="widget.data"
          :location="widget.location"
        />
        <ArticleListContainer
          v-else-if="widget.type === 'top'"
          :title="widget.title"
          :articles="widget.items"
          :url="widget.url"
        />
        <QuickPollContainer
          v-else-if="widget.type === 'quick-poll' && widget.ids && widget.ids.length"
          :in-sidebar="inSidebar"
          :location="widget.location"
          :widget-id="widget.id"
        />
        <WidgetsRotator
          v-else-if="widget.type === 'html-rotation'"
          :items="widget.data.items"
          :interval="+widget.data.interval"
        />
      </ClientOnly>
    </div>
  </ErrorBoundary>
</template>

<script lang="ts">
  import type { PropOptions } from 'vue';

  import NewsWidget from '~/components/NewsWidget/NewsWidget.vue';
  import WidgetsRotator from '~/components/WidgetsRotator.vue';
  import CommentsWidgetContainer from '~/containers/CommentsWidgetContainer.vue';
  import ArticleListContainer from '~/containers/ArticleListContainer/ArticleListContainer.vue';
  import HtmlWidgetContainer from '~/containers/HtmlWidgetContainer/HtmlWidgetContainer.vue';
  import TaxonomyPostsContainer from '~/containers/TaxonomyPostsContainer/TaxonomyPostsContainer.vue';
  import PostCollectionContainer from '~/containers/PostCollectionContainer/PostCollectionContainer.vue';
  import RecipesWidgetContainer from '~/containers/RecipesWidgetContainer.vue';
  import QuickPollContainer from '~/containers/QuickPollContainer/QuickPollContainer.vue';
  import RecommendationBlock from '~/components/RecommendationBlock/RecommendationBlock.vue';
  import BlockPromise from '~/components/BlockPromise/BlockPromise.vue';
  import TilesRubrics from '~/components/TilesRubrics/TilesRubrics.vue';
  import HealthButtonCollection from '@/containers/HealthButtonCollection/HealthButtonCollection.vue';
  import ErrorBoundary from '@devhacker/shared/components/ErrorBoundary.vue';
  import { isProductionStand } from '~/constants/config';

  import { formattingItemSection } from '~/utils/formattingData';
  import { useIndexStore } from '~/store';

  export default defineNuxtComponent({
    name: 'WidgetsRenderer',
    components: {
      CommentsWidgetContainer,
      ArticleListContainer,
      WidgetsRotator,
      HtmlWidgetContainer,
      TaxonomyPostsContainer,
      PostCollectionContainer,
      RecipesWidgetContainer,
      QuickPollContainer,
      NewsWidget,
      RecommendationBlock,
      BlockPromise,
      TilesRubrics,
      HealthButtonCollection,
      ErrorBoundary,
    },
    props: {
      widget: {
        required: true,
        type: Object,
      } as PropOptions<TWidget>,
      inSidebar: {
        type: Boolean,
        default: false,
      } as PropOptions<boolean>,
      recipesWidgetDisplayType: {
        type: String,
        default: 'default',
      } as PropOptions<'default' | 'big-tiles'>,
      recipesWidgetEventCategory: {
        type: String,
        required: true,
      } as PropOptions<string>,
    },

    setup() {
      const indexStore = useIndexStore();
      const { isHeadScriptsLoaded } = storeToRefs(indexStore);

      return {
        isProductionStand,
        isHeadScriptsLoaded,
      };
    },

    methods: {
      onRecipesWidgetShowMore(data: Record<string, string>): void {
        this.$emit('recipes-widget-show-more', data);
      },

      onRecipesWidgetClickRecipe(data: Record<string, string>): void {
        this.$emit('recipes-widget-click-recipe', data);
      },

      getFormattingArticles(articles: ISinglePostServerResponse[]): ISingleArticleServerResponse[] {
        return articles.map((article: ISinglePostServerResponse) => formattingItemSection(article));
      },
    },
  });
</script>
